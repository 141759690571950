import { useState } from 'react'

export function useWithDelay(fn, delay, { stayDelayed = true } = {}) {
  const [isDelaying, setIsDelay] = useState(false)
  return [
    (...args) => {
      setIsDelay(true)
      setTimeout(() => {
        if (!stayDelayed) {
          setIsDelay(false)
        }
        fn(...args)
      }, delay)
    },
    isDelaying,
  ]
}
