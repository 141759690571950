import React from 'react'
import styled from 'styled-components'
import Navbar from './Navbar'
import { breakpoints, layout, borders } from '../theme'
import DemandeShapes from './DemandeShapes'
import Footer from './Footer'
import { StyledButton } from '@monbanquet/crumble'

const DemandeLayout = props => (
  <StyledDemandeLayout>
    <DemandeShapes />
    <Navbar />
    <div className="page-content">{props.children}</div>
    <Footer />
  </StyledDemandeLayout>
)

const StyledDemandeLayout = styled.section`
  background-color: #f5f5f5;

  .page-content {
    min-height: 100vh;
    padding-top: ${layout.navbarHeight.normal}px;
    margin: 0 auto;
    max-width: 850px;
    width: ${layout.width};
    text-align: center;

    h1 {
      font-family: Centra Bold;
    }
  }

  .btn-bar {
    display: flex;
    margin: 30px 0;

    ${StyledButton} {
      padding: 0 40px;
    }

    & > *:first-child {
      margin-right: auto;
      margin-left: 0;
      background: transparent;
      border: 1px solid ${borders.color.light};
      display: flex;
      align-items: center;

      &:hover {
        box-shadow: 0 2px 4px 0 rgba(126, 126, 126, 0.11);
      }

      div {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .btn-bar {
      ${StyledButton} {
        padding: 0 26px;
      }
    }
  }
`

export default DemandeLayout
export { StyledDemandeLayout }
