import { useEffect } from 'react'
import { navigate } from '../components/Link'

export function useRedirect(redirectCondition, url) {
  useEffect(() => {
    if (redirectCondition) {
      navigate(url)
    }
  }, [])
  return redirectCondition
}
