import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../theme'
import Shape, { StyledShape } from './Shape'

const DemandeShapes = () => (
  <StyledDemandeShapes>
    <Shape
      shapeStyle={{
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        border: '10px solid #0F7762',
        left: '10%',
        top: '20%',
      }}
    />
    <Shape
      shapeStyle={{
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        border: '10px solid #BC8046',
        left: '5%',
        top: '40%',
      }}
    />
    <Shape
      shapeStyle={{
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        backgroundColor: '#0F7762',
        left: '15%',
        top: '65%',
      }}
    />
    <Shape
      shapeStyle={{
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        backgroundColor: '#0F7762',
        right: '20%',
        top: '15%',
      }}
    />
    <Shape
      shapeStyle={{
        width: '35px',
        height: '19px',
        border: '10px solid #030101',
        borderBottom: 'none',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        transform: 'matrix(-0.87, 0.48, -0.48, -0.87, 0, 0)',
        right: '15%',
        top: '40%',
      }}
    />
  </StyledDemandeShapes>
)

const StyledDemandeShapes = styled.section`
  ${StyledShape} {
    position: absolute;
    z-index: 0;

    @media (max-width: ${breakpoints.desktop}px) {
      display: none;
    }
  }
`

export default DemandeShapes
export { StyledDemandeShapes }
