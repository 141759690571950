import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'
import { colors, breakpoints } from '../theme'

const SalesPictureTitleWithData = props => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAsset(title: { eq: "sales-picture-default" }) {
          title
          fixed(width: 70, height: 70, quality: 90) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    `}
    render={result => (
      <SalesPictureTitle {...props} picture={result.contentfulAsset} />
    )}
  />
)

const SalesPictureTitle = ({ title, subTitle, picture, ...props }) => (
  <StyledSalesPictureTitle {...props}>
    <Img
      fixed={picture.fixed}
      alt={picture.title}
      className="pic"
      loading="lazy"
    />
    <h1>{title}</h1>
    {subTitle && <h2>{subTitle}</h2>}
  </StyledSalesPictureTitle>
)

const StyledSalesPictureTitle = styled.div`
  padding-top: 20px;

  .pic {
    position: relative;
    display: block !important;
    margin: auto;
    margin-bottom: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  h1 {
    text-align: center;
    font-size: 40px;
    color: #383838;
  }

  h2 {
    color: ${colors.brand.black};
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    font-weight: normal;
    line-height: 24px;
  }

  @media (max-width: ${breakpoints.desktop}px) {
    h1 {
      font-size: 28px;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    h1 {
      font-size: 22px;
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    padding-top: 40px;
  }
`

export default SalesPictureTitleWithData
export { StyledSalesPictureTitle }
