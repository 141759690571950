import React, { useContext } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import { CheckoutContext } from '../../components/context/CheckoutContext'
import { breakpoints, colors } from '../../theme'
import { CustomerContext } from '../../components/context/CustomerContext'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { TextInput, Button, Pulse } from '@monbanquet/crumble'
import { navigate } from '../../components/Link'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { useWithDelay } from '../../hooks/useWithDelay'
import DemandeLayout from '../../components/DemandeLayout'
import { httpFetch, computeNextPageWithBaseUri } from '../../util'

const schema = yup.object().shape({
  firstName: yup.string().required('Indiquez votre prénom'),
  lastName: yup.string().required('Indiquez votre nom'),
})

const NamesPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')
  const { customer, setCustomer } = useContext(CustomerContext)

  const {
    state: { contactFirstName, contactLastName },
    dispatch: dispatchCheckoutCtx,
  } = useContext(CheckoutContext)

  const noValidate = useNoValidate()

  const submit = () => {
    if (customer) {
      httpFetch(
        `${process.env.GATSBY_VENUS_BACK_URL}/api/account/update-profile`,
        {
          method: 'POST',
          body: {
            firstName: contactFirstName,
            lastName: contactLastName,
          },
        },
      ).then(() => {
        setCustomer({
          ...customer,
          firstName: contactFirstName,
          lastName: contactLastName,
        })
      })
    }

    const nextUrl = computeNextPageWithBaseUri('demande', 'noms', {
      isUserLoggedIn: !!customer,
      customer,
    })

    navigate(nextUrl, { state: { next: true } })
  }

  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  const { formal, firstNameProps, lastNameProps } = useFormalWithProps(
    {
      firstName: contactFirstName,
      lastName: contactLastName,
    },
    {
      schema,
      onSubmit: submitWithDelay,
    },
  )

  return (
    <StyledNamesPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={<>{`À qui dois-je faire parvenir le devis\u00A0?`}</>}
              subTitle={
                <p>
                  {`Vous pourrez indiquer plus tard le contact de facturation.`}
                </p>
              }
            />
            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err || formal.errors)
                }
              }}
            >
              <div className="names">
                <TextInput
                  id="firstName"
                  label="Prénom"
                  placeholder="Prénom"
                  autoComplete="given-name"
                  secondary
                  required
                  autoFocus
                  {...firstNameProps}
                  onChange={cfn => {
                    formal.change('firstName', cfn)
                    dispatchCheckoutCtx({
                      type: 'UPDATE',
                      payload: {
                        contactFirstName: cfn,
                      },
                    })
                  }}
                />
                <TextInput
                  id="lastName"
                  label="Nom"
                  placeholder="Nom"
                  autoComplete="family-name"
                  secondary
                  required
                  {...lastNameProps}
                  onChange={cln => {
                    formal.change('lastName', cln)
                    dispatchCheckoutCtx({
                      type: 'UPDATE',
                      payload: {
                        contactLastName: cln,
                      },
                    })
                  }}
                />
              </div>
              <div className="btn-bar">
                <Button id="previous" onClick={() => navigate(-1)}>
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledNamesPage>
  )
}

const StyledNamesPage = styled.div`
  .page-content {
    max-width: 500px;
  }

  form {
    margin-top: 40px;
    & > * {
      margin-bottom: 20px;
    }
    > .error {
      margin-top: 10px;
      color: ${colors.brand.dark2};
    }
  }

  .names {
    display: flex;
    > * {
      flex: 1 1 0;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .dates,
    .names {
      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`

export default NamesPage
export { StyledNamesPage }
